import axios from 'axios';
import _ from 'lodash';
import queryString from 'query-string';

const server_url = process.env.REACT_APP_SERVER_URL;

const getCountBadgeActionForAdminKC = async (token, params) => {
  const query = queryString.stringify(params);
  return await axios
    .get(
      `${server_url}/api/badgeAction/adminKC/getCountBadgeActionForAdminKC?${query}`,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    )
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const createBadgeActionForAdminKC = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/adminKC/createBadgeActionForAdminKC`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const getBadgeActionForAdminKCDistributorByModule = async (token, params) => {
  const query = queryString.stringify(params);
  return await axios
    .get(
      `${server_url}/api/badgeAction/adminKC/getBadgeActionForAdminKCDistributorByModule?${query}`,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    )
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const countBadgeActionForAdminKCByModule = async (token, params) => {
  const query = queryString.stringify(params);
  return await axios
    .get(
      `${server_url}/api/badgeAction/adminKC/count/getBadgeActionForAdminKCByModule?${query}`,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    )
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const createBadgeActionForDistributor = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/distributor/createBadgeActionForDistributor`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}
const updateViewBadgeActionByModule = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/distributor/updateViewBadgeActionByModule`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const getCountBadgeActionForDistributor = async (token, params) => {
  const query = queryString.stringify(params);
  return await axios
    .get(
      `${server_url}/api/badgeAction/distributor/getCountBadgeActionForDistributor?${query}`,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    )
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const updateNotRequiredActionByModule = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/distributor/updateNotRequiredActionByModule`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const updateNotRequiredActionForOrder = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/distributor/updateNotRequiredActionForOrder`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const updateMultipleNotRequiredActionByModule = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/distributor/updateMultipleNotRequiredActionByModule`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const createBadgeActionForRetailer = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/distributor/createBadgeActionForRetailer`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const createBadgeActionForRetailerInDistributorModule = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/distributor/createBadgeActionForRetailerInDistributorModule`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const getChangeAddressSalesBadgeAction = async (token, params) => {
  const query = queryString.stringify(params);
  return await axios
    .get(
      `${server_url}/api/badgeAction/distributor/getChangeAddressSalesBadgeAction?${query}`,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    )
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const readSalesBadgeAction = async (token, params) => {
  const query = queryString.stringify(params);
  return await axios
    .get(
      `${server_url}/api/badgeAction/distributor/readSalesBadgeAction?${query}`,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    )
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const getChangeAddressConsignmentBadgeAction = async (token, params) => {
  const query = queryString.stringify(params);
  return await axios
    .get(
      `${server_url}/api/badgeAction/distributor/getChangeAddressConsignmentBadgeAction?${query}`,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    )
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

export {
  getCountBadgeActionForAdminKC,
  createBadgeActionForAdminKC,
  getBadgeActionForAdminKCDistributorByModule,
  countBadgeActionForAdminKCByModule,
  createBadgeActionForDistributor,
  updateViewBadgeActionByModule,
  getCountBadgeActionForDistributor,
  updateNotRequiredActionByModule,
  updateNotRequiredActionForOrder,
  updateMultipleNotRequiredActionByModule,
  createBadgeActionForRetailer,
  createBadgeActionForRetailerInDistributorModule,

  getChangeAddressSalesBadgeAction,

  readSalesBadgeAction,

  getChangeAddressConsignmentBadgeAction
}